var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "m-b_4" },
      [
        _c("InputPassword", {
          attrs: { inputId: "pwd1", required: true, state: _vm.pwd1State },
          on: { input: _vm.checkPassword },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [_vm._v(" Password ")]
              },
              proxy: true,
            },
            {
              key: "requiredAlertMessage",
              fn: function () {
                return [_vm._v(" Please enter Password. ")]
              },
              proxy: true,
            },
            {
              key: "alertMessage",
              fn: function () {
                return [_vm._v(" Password conditions not met. ")]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.pwd1Value,
            callback: function ($$v) {
              _vm.pwd1Value = $$v
            },
            expression: "pwd1Value",
          },
        }),
        _c("span", [_vm._v("Password Requirements:")]),
        _c("div", [
          _c("i", {
            staticClass: "m-r_4 fa",
            class: this.pwdCheck.hasCharMin
              ? "fa-check-circle c_success-n1"
              : "fa-ban c_alert-n1",
          }),
          _vm._v("8 characters minimum with no spaces"),
        ]),
        _c("div", [
          _c("i", {
            staticClass: "m-r_4 fa",
            class: this.pwdCheck.hasNum
              ? "fa-check-circle c_success-n1"
              : "fa-ban c_alert-n1",
          }),
          _vm._v("Contains at least one number"),
        ]),
        _c("div", [
          _c("i", {
            staticClass: "m-r_4 fa",
            class: this.pwdCheck.hasLetter
              ? "fa-check-circle c_success-n1"
              : "fa-ban c_alert-n1",
          }),
          _vm._v("Contains at least one letter"),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "m-b_4" },
      [
        _c("InputPassword", {
          attrs: { inputId: "pwd2", required: true, state: _vm.pwd2State },
          on: { input: _vm.confirmPassword },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [_vm._v(" Confirm Password ")]
              },
              proxy: true,
            },
            {
              key: "requiredAlertMessage",
              fn: function () {
                return [_vm._v(" Please Confirm Password. ")]
              },
              proxy: true,
            },
            {
              key: "alertMessage",
              fn: function () {
                return [_vm._v(" Password does not match. Please try again. ")]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.pwd2Value,
            callback: function ($$v) {
              _vm.pwd2Value = $$v
            },
            expression: "pwd2Value",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }