var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "question font_ui" }, [
    _vm.hasLabel
      ? _c(
          "label",
          {
            staticClass:
              "label-holder flex font-size_up-1 font_display font_medium p-y_2",
            class: [
              {
                c_alert: _vm.state == "alert",
              },
              {
                c_alert: _vm.state == "requiredAlert",
              },
              {
                c_warning: _vm.state == "warning",
              },
            ],
            attrs: { for: "input_" + _vm.id },
          },
          [
            _c(
              "span",
              { staticClass: "text cell flex_shrink" },
              [
                _vm._t("default"),
                _vm.required
                  ? _c(
                      "span",
                      { staticClass: "required-holder flex_shrink font_n5" },
                      [
                        _c("i", {
                          staticClass:
                            "fas fa-asterisk c_warning vertical-align_top",
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "input-holder flex self_end" },
      [
        _vm.icon
          ? _c("ValueIcon", {
              staticClass: "flex_none p-x_3 p-y_0 br-tl_radius br-bl_radius",
              attrs: {
                state: _vm.state,
                icon: _vm.icon,
                inputNameTarget: "input_" + _vm.id,
              },
            })
          : _vm._e(),
        _vm._t("input"),
        _vm.postLabel
          ? _c("div", {
              staticClass:
                "\n\t\t\t\tbr_solid br_2\n\t\t\t\tbr-l_0\n\t\t\t\tp-y_2\n\t\t\t\tfont_medium\n\t\t\t\tflex_none\n\t\t\t\tp-x_4\n\t\t\t\tlh_3\n\t\t\t\tself_strech\n\t\t\t",
              class: _vm.inputPrePostStyles,
              domProps: { innerHTML: _vm._s(_vm.postLabel) },
              on: { click: _vm.onClickPostLabel },
            })
          : _vm._e(),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "font-size_down" },
      [
        _vm.state == "requiredAlert"
          ? _c(
              "messageHolder",
              { attrs: { state: "alert" } },
              [
                _vm._t("requiredAlertMessage", function () {
                  return [_vm._v("This input is required.")]
                }),
              ],
              2
            )
          : _vm._e(),
        _vm.state == "alert"
          ? _c(
              "messageHolder",
              { attrs: { state: "alert" } },
              [_vm._t("alertMessage")],
              2
            )
          : _vm._e(),
        _vm.state == "warning"
          ? _c(
              "messageHolder",
              { attrs: { state: "warning" } },
              [_vm._t("warningMessage")],
              2
            )
          : _vm._e(),
        _vm.state == "success"
          ? _c(
              "messageHolder",
              { attrs: { state: "success" } },
              [_vm._t("successMessage")],
              2
            )
          : _vm._e(),
        _vm.state == "info"
          ? _c(
              "messageHolder",
              { attrs: { state: "info" } },
              [_vm._t("infoMessage")],
              2
            )
          : _vm._e(),
        _vm.state == "accent"
          ? _c(
              "messageHolder",
              { attrs: { state: "accent" } },
              [_vm._t("accentMessage")],
              2
            )
          : _vm._e(),
        _c(
          "span",
          { staticClass: "opacity_7 font_italic m-t_2 block" },
          [_vm._t("hint")],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }