<template>
  <div class="p_4 max-w_50 m_auto">
    <div v-if="!securityquestionview">
      <div class="text_center">
        <h1 class="c_acc font_6 text_center">Forgot Your Password?</h1>
      </div>
      <div v-if="activationCode!=''" class="alert alert-alert show" role="alert">
        <div class="relative">
          <div class="flex">
            <div class="font_10 text_center p_3 justify_center self_center">
              <i class="far fa-exclamation-triangle"></i>
            </div>
            <div class="flex_auto p_4 p-y_2 self_center">
              <p>
                The activation link is invalid. Please try to reset your password again.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="User.StatusCode!='' && User.StatusCode =='1' && User.StatusMessage!=''" class="alert alert-alert show" role="alert">
        <div class="relative">
          <div class="flex">
            <div class="font_10 text_center p_3 justify_center self_center">
              <i class="far fa-exclamation-triangle"></i>
            </div>
            <div class="flex_auto p_4 p-y_2 self_center">
              <span v-html="User.StatusMessage"></span>
            </div>
          </div>
        </div>
      </div>
      <form id="formForgotPassword" class="p_4 max-w_30 m_auto" novalidate v-on:submit.prevent="onSubmit">
        <div class="text_center">
          <p><i class="far font_10 fa-lock fas c_secondary"></i></p>
          <p>Enter your email address or username to verify your identity and retrieve your password.</p>
        </div>
        <fieldset>
          <div class="m-b_4">
            <TextInput ref="TextInput" :id="'username'" :type="'text'" v-model.trim="User.UserName" :placeholder="'Email or Username'" :required="true" :state="usernameState" @input="onDisabledCheck('username')">
              <template v-slot:default>
                Email or Username
              </template>
              <template v-slot:requiredAlertMessage>
                Please enter email address or username.
              </template>
            </TextInput>
          </div>
          <div class="text_center" @click="onDisabledCheck('all')">
            <Btn :isDisabled="isDisabled">
              <span class="p-r_3">Submit</span>
              <i v-if="loading" id="btnSpinner" class="spinner fa fa-spinner fa-spin self_center"></i>
            </Btn>
          </div>
        </fieldset>
      </form>
      <div>
        <ACCMemberCare></ACCMemberCare>
      </div>
    </div>
    <div v-if="securityquestionview">
      <div v-if="User.StatusCode =='2'">
        <SecurityQuestions :SecurityUser="User" :loading="loading" :buttontext="securityquestionbuttontext" :titletext="securityquestiontitletext" @onSecurityButtonClick="onSecurityButtonClick"></SecurityQuestions>
      </div>
      <div v-if="User.StatusCode>='3'">
          <Notifications :SecurityUser="User" :loading="loading" :step="'forgotpassword'" @onResendemail ="onSecurityButtonClick"></Notifications>
      </div>
    </div>
  </div>
  
</template>
<script>
  import TextInput  from '@/components/BasicForms/Input.Text.vue'
  import Btn from '@/components/subComponents/Btn.vue'
  import ACCMemberCare from "./ACCMemberCare.vue";
  import SecurityQuestions from '../views/newSecurityQuestions.vue'
  import Notifications from '../views/Notifications.vue'
  import axios from 'axios'
  export default {
    name: 'ForgotPassword',
    components: {
      TextInput,
      Btn,
      ACCMemberCare,
      axios,
      SecurityQuestions,
      Notifications
    },
    data: () => ({
      loading: false,
      User: {
        UserName: '',
        CannotRememberSecurityQuestion: false,
        SecurityQuestion1: '',
        Answer1: '',
        SecurityQuestion2: '',
        Answer2: '',
        Resend: false,
        StatusCode: '',
        StatusMessage: '',
        Attempts: 0,
        ActivationId:''
      },
      usernameState: '',
      securityquestionview: false,
      //notificationsview:false,
      securityquestiontitletext: "We need to make sure you're you before you can receive your password.",
      securityquestionbuttontext: 'Reset Password',
      activationCode: ''
    }),
    computed: {
      isDisabled() {  
        if (this.loading || this.state == "requiredAlert") {
          return true;
        }
        if (this.User.UserName) {
          return this.User.UserName.length <= 0;
        }
        return true;
      },
     
    },
    mounted: function () {
      this.$refs.TextInput.focus()
      //if forgotpassword coming from reset password page where activation code is invalid 
      this.activationCode = this.$route.query.activationCode != undefined ? this.$route.query.activationCode :''
    },
    methods: {
      onSecurityButtonClick: function (b) {
        if (b.target.classList.contains('resendemail'))
          this.User.CannotRememberSecurityQuestion = true
        this.onSubmit()
      },
      onDisabledCheck(eventTargetDOM) {
        if (eventTargetDOM == 'all' || eventTargetDOM == 'username') {
          if (this.User.UserName == '') { this.usernameState = 'requiredAlert'; } else { this.usernameState = ''; }
        }
      },
      onSubmit() {
        if (this.User.UserName.length > 0) {
          this.activationCode = ''
          this.loading = true
          axios.post('/ForgotPassword/ForgotPassword', this.User)
            .then((response) => {
              if (response.data) {
                this.User = response.data
                if (this.User.StatusCode == "1") {
                  this.loading = false
                  this.securityquestionview = false
                  //  this.notificationsview = false
                  window.scrollTo(0, top);
                }
                else if (this.User.StatusCode == "2" || this.User.StatusCode == "3" || this.User.StatusCode == "4") {
                  this.securityquestionview = true
                  this.loading = false
                  if (this.User.StatusCode == "2")
                    window.scrollTo(0, top);
                  //else (this.User.StatusCode == "3" || this.User.StatusCode == "4")
                  //{ this.notificationsview= true }

                  // this.$router.push({ name: 'SecurityQuestions', params: { User:JSON.stringify(this.User), isforgotusername: false} })
                  // this.$router.push({ name: 'SecurityQuestions', query: { 'User': JSON.stringify(this.User)}})
                }
                else if (this.User.StatusCode == "5") {
                  this.securityquestionview = false
                  // this.notificationsview = false
                  this.loading = false
                  this.$router.push({ name: 'ResetPassword', query: { 'activationCode': this.User.ActivationId } })
                }
                else {
                  this.loading = false
                  this.securityquestionview = false
                  window.scrollTo(0, top);
                }

              }
            }).catch((error) => {
              this.User.StatusCode = 1
              this.User.StatusMessage = 'An issue was encountered connecting to an ACC partner site. '
              this.loading = false
              console.log('vue' + JSON.stringify(this.User))
              console.log(error)
            });
        }
      },
      brwoserback(to, from, next) {
        if (this.User.StatusCode != "5" && this.User.StatusCode !="3") {
          const answer = window.confirm('Changes you made may not be saved.')
          if (answer) {
            if (this.securityquestionview) {
            if (this.User.StatusCode == "3" || this.User.StatusCode == "4") {
                next()
              } else {
                this.securityquestionview = false
                this.User.UserName = ""
                this.$router.push({ name: 'ForgotPassword', path: 'ForgotPassword' })
              }
            }
            else {
              next()
           }
          } else {
            next(false)
          }
        }
        else { next()}
    },
    },
    //watch: {
    //  User: function (newvale, oldvalue) {
    //    console.log(newValue)
    //    console.log(oldValue)
    //  }
    //}
    beforeRouteLeave(to, from, next) {
      this.brwoserback(to, from, next)
    },
    created() {
      // window.addEventListener('beforeunload', this.handler);
      window.addEventListener("beforeunload", (e) => {
  // if (this.securityquestionview) {
        if (this.User.StatusCode == "5" || this.User.StatusCode == "3") {

          } else {

            if (!this.success) {

              e.preventDefault()
              e.returnValue = ''
            }
            //  this.$router.push({ name: 'ForgotUserName' })
          }
       // }
        return false;
      });
    },
  }
</script>
