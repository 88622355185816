import { render, staticRenderFns } from "./InputMessageHolder.vue?vue&type=template&id=6637ad52&scoped=true&"
import script from "./InputMessageHolder.vue?vue&type=script&lang=js&"
export * from "./InputMessageHolder.vue?vue&type=script&lang=js&"
import style0 from "./InputMessageHolder.vue?vue&type=style&index=0&id=6637ad52&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6637ad52",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\UpgradeFederated\\ACCFederatedLogin\\vue-app-login\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6637ad52')) {
      api.createRecord('6637ad52', component.options)
    } else {
      api.reload('6637ad52', component.options)
    }
    module.hot.accept("./InputMessageHolder.vue?vue&type=template&id=6637ad52&scoped=true&", function () {
      api.rerender('6637ad52', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/subComponents/InputMessageHolder.vue"
export default component.exports