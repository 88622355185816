var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "ul_none" },
    _vm._l(_vm.professions, function (prof, index) {
      return _c(
        "li",
        {
          key: "cata" + index,
          staticClass: "c_black-8 h:c_black-9 h:bg_primary-3 p-x_3 p-y_2",
          class: _vm.isActive(prof),
          on: {
            click: function ($event) {
              return _vm.onSelectCategory(prof)
            },
          },
        },
        [
          _c("i", { staticClass: "far fa-arrow-circle-right m-r_3" }),
          _vm._v(_vm._s(prof["Name"])),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }