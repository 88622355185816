var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p_4 max-w_50 m_auto" }, [
    !_vm.securityquestionview
      ? _c("div", [
          _vm._m(0),
          _vm.activationCode != ""
            ? _c(
                "div",
                {
                  staticClass: "alert alert-alert show",
                  attrs: { role: "alert" },
                },
                [_vm._m(1)]
              )
            : _vm._e(),
          _vm.User.StatusCode != "" &&
          _vm.User.StatusCode == "1" &&
          _vm.User.StatusMessage != ""
            ? _c(
                "div",
                {
                  staticClass: "alert alert-alert show",
                  attrs: { role: "alert" },
                },
                [
                  _c("div", { staticClass: "relative" }, [
                    _c("div", { staticClass: "flex" }, [
                      _vm._m(2),
                      _c(
                        "div",
                        { staticClass: "flex_auto p_4 p-y_2 self_center" },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.User.StatusMessage),
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "form",
            {
              staticClass: "p_4 max-w_30 m_auto",
              attrs: { id: "formForgotPassword", novalidate: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                },
              },
            },
            [
              _vm._m(3),
              _c("fieldset", [
                _c(
                  "div",
                  { staticClass: "m-b_4" },
                  [
                    _c("TextInput", {
                      ref: "TextInput",
                      attrs: {
                        id: "username",
                        type: "text",
                        placeholder: "Email or Username",
                        required: true,
                        state: _vm.usernameState,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.onDisabledCheck("username")
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [_vm._v(" Email or Username ")]
                            },
                            proxy: true,
                          },
                          {
                            key: "requiredAlertMessage",
                            fn: function () {
                              return [
                                _vm._v(
                                  " Please enter email address or username. "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1579365648
                      ),
                      model: {
                        value: _vm.User.UserName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.User,
                            "UserName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "User.UserName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "text_center",
                    on: {
                      click: function ($event) {
                        return _vm.onDisabledCheck("all")
                      },
                    },
                  },
                  [
                    _c("Btn", { attrs: { isDisabled: _vm.isDisabled } }, [
                      _c("span", { staticClass: "p-r_3" }, [_vm._v("Submit")]),
                      _vm.loading
                        ? _c("i", {
                            staticClass:
                              "spinner fa fa-spinner fa-spin self_center",
                            attrs: { id: "btnSpinner" },
                          })
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("div", [_c("ACCMemberCare")], 1),
        ])
      : _vm._e(),
    _vm.securityquestionview
      ? _c("div", [
          _vm.User.StatusCode == "2"
            ? _c(
                "div",
                [
                  _c("SecurityQuestions", {
                    attrs: {
                      SecurityUser: _vm.User,
                      loading: _vm.loading,
                      buttontext: _vm.securityquestionbuttontext,
                      titletext: _vm.securityquestiontitletext,
                    },
                    on: { onSecurityButtonClick: _vm.onSecurityButtonClick },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.User.StatusCode >= "3"
            ? _c(
                "div",
                [
                  _c("Notifications", {
                    attrs: {
                      SecurityUser: _vm.User,
                      loading: _vm.loading,
                      step: "forgotpassword",
                    },
                    on: { onResendemail: _vm.onSecurityButtonClick },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text_center" }, [
      _c("h1", { staticClass: "c_acc font_6 text_center" }, [
        _vm._v("Forgot Your Password?"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "relative" }, [
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "font_10 text_center p_3 justify_center self_center" },
          [_c("i", { staticClass: "far fa-exclamation-triangle" })]
        ),
        _c("div", { staticClass: "flex_auto p_4 p-y_2 self_center" }, [
          _c("p", [
            _vm._v(
              " The activation link is invalid. Please try to reset your password again. "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "font_10 text_center p_3 justify_center self_center" },
      [_c("i", { staticClass: "far fa-exclamation-triangle" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text_center" }, [
      _c("p", [
        _c("i", { staticClass: "far font_10 fa-lock fas c_secondary" }),
      ]),
      _c("p", [
        _vm._v(
          "Enter your email address or username to verify your identity and retrieve your password."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }