var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p_4 max-w_50 m_auto" }, [
    _c("div", [
      _vm._m(0),
      _vm._m(1),
      _c(
        "form",
        {
          staticClass: "p_4 max-w_30 m_auto",
          attrs: { id: "formSecurityQuestions", novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c("fieldset", [
            _c(
              "div",
              { staticClass: "m-b_4" },
              [
                _c("InputSelect", {
                  staticClass: "m-b_4",
                  attrs: {
                    inputId: "Q1",
                    options: _vm.Questions1list,
                    required: true,
                    state: _vm.sq1State,
                  },
                  on: { onStateChange: _vm.updateState },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function () {
                        return [_vm._v(" Verification Question 1 ")]
                      },
                      proxy: true,
                    },
                    {
                      key: "requiredAlertMessage",
                      fn: function () {
                        return [
                          _vm._v(" Please choose Verification Question 1. "),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.SecurityUser.Question1,
                    callback: function ($$v) {
                      _vm.$set(_vm.SecurityUser, "Question1", $$v)
                    },
                    expression: "SecurityUser.Question1",
                  },
                }),
                _c("InputText", {
                  ref: "TextInput",
                  class: "br_radius",
                  attrs: {
                    inputId: "sq1",
                    type: "text",
                    required: true,
                    state: _vm.sqa1State,
                  },
                  on: { onStateChange: _vm.updateState },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function () {
                        return [_vm._v(" Answer 1 ")]
                      },
                      proxy: true,
                    },
                    {
                      key: "requiredAlertMessage",
                      fn: function () {
                        return [
                          _vm._v(" Please submit answer before continuing. "),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.SecurityUser.Answer1,
                    callback: function ($$v) {
                      _vm.$set(_vm.SecurityUser, "Answer1", $$v)
                    },
                    expression: "SecurityUser.Answer1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "m-b_4" },
              [
                _c("InputSelect", {
                  staticClass: "m-b_4",
                  attrs: {
                    inputId: "Q2",
                    options: _vm.Questions2list,
                    required: true,
                    state: _vm.sq2State,
                  },
                  on: { onStateChange: _vm.updateState },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function () {
                        return [_vm._v(" Verification Question 2 ")]
                      },
                      proxy: true,
                    },
                    {
                      key: "requiredAlertMessage",
                      fn: function () {
                        return [
                          _vm._v(" Please choose Verification Question 2. "),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.SecurityUser.Question2,
                    callback: function ($$v) {
                      _vm.$set(_vm.SecurityUser, "Question2", $$v)
                    },
                    expression: "SecurityUser.Question2",
                  },
                }),
                _c("InputText", {
                  class: "br_radius",
                  attrs: {
                    inputId: "sq2",
                    type: "text",
                    required: true,
                    state: _vm.sqa2State,
                  },
                  on: { onStateChange: _vm.updateState },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function () {
                        return [_vm._v(" Answer 2 ")]
                      },
                      proxy: true,
                    },
                    {
                      key: "requiredAlertMessage",
                      fn: function () {
                        return [
                          _vm._v(" Please submit answer before continuing. "),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.SecurityUser.Answer2,
                    callback: function ($$v) {
                      _vm.$set(_vm.SecurityUser, "Answer2", $$v)
                    },
                    expression: "SecurityUser.Answer2",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "text_center",
                on: {
                  click: function ($event) {
                    return _vm.onDisabledCheck("submit")
                  },
                },
              },
              [
                _c("Btn", { attrs: { isDisabled: _vm.isDisabled } }, [
                  _c("div", [
                    _c("span", { staticClass: "p-r_3" }, [_vm._v("Submit")]),
                  ]),
                  _vm.loading
                    ? _c("i", {
                        staticClass:
                          "spinner fa fa-spinner fa-spin self_center",
                        attrs: { id: "btnSpinner" },
                      })
                    : _vm._e(),
                ]),
              ],
              1
            ),
            _c("p", { staticClass: "text_center p_4 resendemail" }, [
              _c(
                "a",
                {
                  staticClass: "resendemail",
                  attrs: { id: "notnow", href: "javascript:void(0);" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("Not Now")]
              ),
            ]),
          ]),
        ]
      ),
    ]),
    _c("div", [_c("ACCMemberCare")], 1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text_center" }, [
      _c("h1", { staticClass: "c_acc font_6" }, [
        _vm._v(" Create Security Questions "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text_center" }, [
      _c("div", { staticClass: "text_center" }, [
        _c("p", [
          _c("i", { staticClass: "far font_10 fa-lock fas c_secondary" }),
        ]),
        _c("div", [
          _c("p", [
            _vm._v(
              " For added security, and for us to provide you better service, please select security questions and answers below."
            ),
          ]),
        ]),
        _c("p", { staticClass: "font_italic" }, [
          _vm._v("Hint: Select questions and answers you will remember."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }