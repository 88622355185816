var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      { staticClass: "br_2 br_acc br_solid" },
      [
        _c("span", { staticClass: "block bg_acc p_3 c_white font_bold" }, [
          _vm._v("I'm a..."),
        ]),
        _c("professional-item", {
          attrs: {
            professions: _vm.prof,
            currentSelection: _vm.currentSelection,
          },
          on: { onSelect: _vm.onSelect },
        }),
      ],
      1
    ),
    _vm.subProf.length >= 1
      ? _c(
          "div",
          { staticClass: "br_2 br_acc br_solid" },
          [
            _c("select-profession", {
              attrs: { professions: _vm.subProf, subProfessions: [] },
              on: {
                onChildSelect: _vm.onChildSelect,
                foundEndOfTree: _vm.foundEndOfTree,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }