var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "message-holder ",
      class: {
        "": _vm.state != "",
        "c_alert-n1": _vm.state == "alert",
        "c_warning-n1": _vm.state == "warning",
        "c_info-n1": _vm.state == "info",
        "c_success-n1": _vm.state == "success",
      },
    },
    [
      _vm.state != ""
        ? _c(
            "div",
            { staticClass: "p_2 font_bold inline-flex " },
            [
              _c("Icon", {
                staticClass: "flex_none font-size_down m-r_3",
                staticStyle: { width: "2em", height: "2em" },
                attrs: { state: _vm.state },
              }),
              _c("div", { staticClass: "flex flex_column justify_center" }, [
                _c(
                  "div",
                  {},
                  [
                    _vm._t("default", function () {
                      return [
                        _vm._v('Enter A "' + _vm._s(_vm.state) + '" Message'),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }