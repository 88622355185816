var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("QuestionFieldSet", {
    attrs: {
      id: _vm.id,
      icon: _vm.icon,
      postLabel: _vm.postLabel,
      required: _vm.required,
      state: _vm.thisState,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function () {
            return [_vm._t("default")]
          },
          proxy: true,
        },
        {
          key: "legend",
          fn: function () {
            return [
              _vm._t("legend", function () {
                return [_vm._v(_vm._s(_vm.headertitle))]
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "input",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "grid m-t_4 gap-x_3 gap-y_3",
                  class: _vm.templateClasses,
                },
                _vm._l(_vm.options, function (checkbox, index) {
                  return _c("InputRadioBox", {
                    key: "checkbox" + _vm.id + index,
                    attrs: {
                      value: checkbox,
                      name: _vm.name,
                      required: _vm.required,
                      disabled: _vm.state == "disabled",
                      index: index,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onChange($event)
                      },
                    },
                  })
                }),
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "alertMessage",
          fn: function () {
            return [_vm._t("alertMessage")]
          },
          proxy: true,
        },
        {
          key: "warningMessage",
          fn: function () {
            return [_vm._t("warningMessage")]
          },
          proxy: true,
        },
        {
          key: "successMessage",
          fn: function () {
            return [_vm._t("successMessage")]
          },
          proxy: true,
        },
        {
          key: "infoMessage",
          fn: function () {
            return [_vm._t("infoMessage")]
          },
          proxy: true,
        },
        {
          key: "accentMessage",
          fn: function () {
            return [_vm._t("accentMessage")]
          },
          proxy: true,
        },
        {
          key: "hint",
          fn: function () {
            return [_vm._t("hint")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }