var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex justify_center", class: _vm.areaStyle },
    [
      _vm.showIcon
        ? _c("i", {
            staticClass: "fa fas text_center flex_auto self_center lh_0",
            class: _vm.iconStyle,
          })
        : _vm._e(),
      _vm.showIcon
        ? _c("span", { staticStyle: { width: "1px", height: "1px" } })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }