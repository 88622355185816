<template>
  <div class="p_4 max-w_50 m_auto">
    <div>
      <h1 class="c_acc font_6 text_center">Is This You?</h1>
      </div>
      <div class="alert alert-secondary" role="alert">
        <div class="flex">
          <div class="font_10 text_center p_3 justify_center self_center lh_0">
            <i class="far fa-exclamation-circle"></i>
          </div>
          <div class="flex_auto p_4 p-y_2 self_center">
            <h4 class="alert-heading  font-size_up-1 font_display  font_medium">Our records indicate that you may already have an account.</h4>
            <p>Please verify if any of the following accounts belong to you. If no, click "No, I don't have an account" to create your new account.</p>
          </div>
        </div>
      </div>
     
        <fieldset class="p_0 p-b_3">
          <RadioButtons :inputId="'uniqueRadio'" :title="title" v-bind:options="options" v-model="picked" radioclasses=" p_4 bg_black-2 br_solid br_radius br_1 br_black-2">
          </RadioButtons>
        </fieldset>
        <div class="text_center m-b_4">
          <span v-model="picked.value"></span>
          <span v-model="picked"></span>
          <transitionExpand v-for="(item, index) in options" :key="'item_'+index">
            <div v-if="picked.value==item.value">
              <div v-if="picked.value=='notme'" class="m-x_4" @click="createaccount">
                <Btn :size="'medium'">
                  <span class="p-r_3">Create Free Account</span>
                  <i v-if="loading" id="btnSpinner1" class="spinner fa fa-spinner fa-spin self_center"></i>
                </Btn>
              </div>
              <div v-if="picked.value!='notme'" class="m-x_4">
                <div class="text_center m-t_4">
                  <router-link to="/" class=" flex_column p-y_3 lh_2 p-x_4 font_1 font_2:md ease_out transition_1 f:outline_none text_center br_none inline-block w_auto font_medium font_ui  c_white h:c_white h:bg_primary-n2 br_primary-n3 bg_primary  shadow_overlap-light  undefined cursor_pointer  br_radius ">Log In</router-link>
                </div>
              </div>
              <div v-if="picked.value!='notme'" class="m-x_4" @click="LogInclick">
                <Btn :size="'medium'">
                  <span class="p-r_3">Log In</span>
                  <i v-if="loading" id="btnSpinner1" class="spinner fa fa-spinner fa-spin self_center"></i>
                </Btn>
              </div>
            </div>
          </transitionExpand>
        </div>
      
    </div>
</template>

<script>
  import RadioButtons from "@/components/BasicForms/Input.RadioButtons.vue"
  import ACCMemberCare from "./ACCMemberCare.vue";
  import Btn from '@/components/subComponents/Btn.vue'
  import transitionExpand from "@/components/subComponents/TransitionExpand.vue"
  export default {
    name: 'IsThatYou',
    components: {
      ACCMemberCare,
      RadioButtons,
      Btn,
      transitionExpand
    },
    props: {
      options: Array,
      targeturl: ''
    },
    data: () => ({
      picked: '',
      loading: false,
      title: 'Do you recognize any of these accounts?',
     
    }),
    methods: {

      createaccount() {
        this.loading = true
        this.$emit("freeaccount", this.picked.value);
      },
      LogInclick() {
        window.location.href = "/ACCFederatedLogin/PostSP" + this.targeturl
      }
    },
    computed: {
      templateClasses() {
        let classes = 'template-x_20';
        if (this.options <= 4) { classes = 'template-x_30' }
        return classes;
      },
      
      inputPrePostStyles() {
        let styles = "";
        switch (this.state) {
          case "requiredAlert":
          case "alert":
            styles += " c_alert-1 br_alert-n1 ";
            break;
          case "warning":
            styles += "   c_warning br_warning-n1 ";
            break;
          case "success":
            styles += "  br_success-n1 c_success ";
            break;
          case "disabled":
            styles += " c_black-3  br_black-3 ";
            break;
          default:
            styles += "c_black-6  br_black-3 ";
            break;
        }
        return styles;
      },
      inputStyles() {
        let styles = "";
        switch (this.state) {
          case "requiredAlert":
          case "alert":
            styles += " c_alert br_alert-n1 ";
            break;
          case "warning":
            styles += "  c_warning br_warning-n1 ";
            break;
          case "success":
            styles += " br_success-n2 c_success ";
            break;
          case "disabled":
            styles += " c_black bg_black-2 br_black-3 texture_disabled";
            break;
          default:
            styles += " c_black bg_white-0 br_black-3 ";
            break;
        }
        if (this.icon) {
          styles += " br-l_0";
        }
        return styles;
      },
      legendStyles() {
        let styles = "";
        switch (this.state) {
          case "requiredAlert":
          case "alert":
            styles += " c_alert-n3  ";
            break;
          case "warning":
            styles += " c_warning-n3  ";
            break;
          case "success":
            break;
          case "disabled":
            styles += " c_black-4  ";
            break;
          default:
            break;
        }
        return styles;
      },
      LabelStyles() {
        let styles = "";
        switch (this.state) {
          case "requiredAlert":
          case "alert":
            styles += " c_alert-n3  ";
            break;
          case "warning":
            styles += " c_warning-n3  ";
            break;
          case "success":
            break;
          case "disabled":
            styles += " c_black-4  ";
            break;
          default:
            break;
        }
        switch (this.size) {
          case "large":
            styles += " p-l_4 font-size_up";
            break;
          case "tiny":
            styles += " p-l_2 font-size_down";
            break;
          default:
            styles += " p-l_3";
            break;
        }
        return styles;
      },
      fieldsetStyles() {
        let styles = "br_solid br_1 br_radius";
        switch (this.state) {
          case "requiredAlert":
          case "alert":
            styles += " br_alert-n1  ";
            break;
          case "warning":
            styles += " br_warning-n1  ";
            break;
          case "success":
            break;
          case "disabled":
            break;
          default:
            styles = "br_none";
            break;
        }
        return styles;
      },
      radioSizeStyles() {
        let styles = "lh_0 ";
        switch (this.size) {
          case "tiny":
            styles += "p-x_1 p-t_1 ";
            break;
          case "small":
            styles += " p-y_2 ";
            break;
          case "medium":
            styles += " m-x_n3 ";
            break;
          case "large":
            styles += " m-x_n3 p-y_3    ";
            break;
          default:
            styles += "";
            break;
        }
        return styles;
      }
    },
  }
</script>

<style scoped>
  .question .input:focus {
    border: inherit;
    background: inherit;
  }
</style>
