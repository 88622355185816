<template>
  <div class="p_4 max-w_50 m_auto">
    <h1 class="c_acc font_6 text_center">Verify Account Status</h1>
    <div>
      <p>We want to make sure you don't already have an ACC account. Please click the option that applies to you to continue:</p>
    </div>
    <form id="formRegister">
      <fieldset class="p_0 p-b_3">
        <RadioButtons :inputId="'uniqueRadio'" v-bind:options="options" v-model="picked" radioclasses=" p_4 bg_black-2 br_solid br_radius br_1 br_black-2">
          <template v-slot:default>
            We want to make sure you don't already have an ACC account. Please click the option that applies to you to continue:
          </template>
        </RadioButtons>
      </fieldset>
      <transitionExpand v-for="(item, index) in options" :key="'item_'+index">
        <div v-html="item.content" v-if="picked.value==item.value" class="m-x_4"></div>
      </transitionExpand>
     </form>
    <ACCMemberCare></ACCMemberCare>
  </div>
</template>

<script>
 
   import RadioButtons from "@/components/BasicForms/Input.RadioLogin.vue"
  import transitionExpand from "@/components/subComponents/TransitionExpand.vue"
  import ACCMemberCare from "./ACCMemberCare.vue"
  export default {
    name: 'PreRegister',
    components: {
      ACCMemberCare,
      RadioButtons,
      transitionExpand
    },
    data: () => ({
       picked: {

      },
      returnurl:'',
     
    }),
    beforeMount: function () {
     
      //if (this.$route.query.returnUrl != undefined && this.$route.query.returnUrl != '')
      //  this.returnurl = "?returnUrl=" + this.$route.query.returnUrl
      
      if (this.$route.fullPath != '' && this.$route.fullPath.split('?')) {
        if (this.$route.fullPath.split('?').length > 0) {
          this.returnurl = ( this.$route.fullPath.split('?')[1] != undefined ? '?'+ this.$route.fullPath.split('?')[1]  : '')
        }
      }
      console.log(this.returnurl)
      },
    computed: {

      options() {
        return [
          {
            value: 1,
            label: "I am an ACC Member",
            content: "<div  style='height:auto;'><p>ACC Members do not need to create an account here. Members receive access to exclusive content and special discounts that require logging in with your existing member account. If you have forgotten your login information, you can use your current primary email address in your profile to login or  " + '\n'
              + "<a href=" + document.getElementById("main-content").getAttribute("data-salesforceforgotpassword") + this.returnurl + " > reset your password. </a></p> " + '\n'
              + "<p>If you've recently joined the ACC, check your welcome letter for your ACC account information.</p></div>" + '\n'
              + "<a class='btn btn-lg btn-primary' href="+document.getElementById("main-content").getAttribute("data-url-name")+"/AccFederatedlogin/PostSP"+ this.returnurl +"> Continue to Log In</a>"
          },
          {
            value: 2,
            label: "I am not a member, but have created an account",
            content: "<div  style='height:auto;'><p>Nonmembers who have created an account previously do not need to register again. Creating duplicate accounts make it difficult to track your ACC educational activities for credit.</p>" + '\n'
              + " <div  style='height:auto;'><p>If you have forgotten your password, you can reset your password.</p></div>" + '\n'
              + "<a class='btn btn-lg btn-primary' href=" + document.getElementById("main-content").getAttribute("data-salesforceforgotpassword") + window.location.search.toString() + " > Reset Password </a>"
          },
          {
            value: 3,
            label: "I have not created an account",
            content: "<div  style='height:auto;'><p>Please note that creating an ACC account does not make you a member of the ACC, or give you access to member-only content, but it does allow you to register for events, purchase ACC products, and redeem promo codes for educational products or discounts.</p> </div>" + '\n'
              + "<div style='height:auto; font-style:italic'><p><i>Note to members: Creating a new account will disconnect you from all of your ACC member benefits and educational credits.</i></p></div>" + '\n'
              + "<a  href='#/Registration" + this.returnurl + "' class='btn btn-lg btn-primary'>Create Free Account</a>"
          }
        ]
      }
    }
  }
</script>

