import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
Vue.prototype.$AccUrl = process.env.VUE_APP_URL_ACCURL
Vue.prototype.RecaptchPublicKey = process.env.VUE_APP_GOOGLE_RECAPTCHPUBLICKEY
//Vue.config.devtools = true
//custom directives
Vue.directive('focus', {
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})
new Vue({
  router, 
  render: h => h(App),
}).$mount('#app')
