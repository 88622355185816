var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p_4 max-w_50 m_auto" },
    [
      _c("h1", { staticClass: "c_acc font_6 text_center" }, [
        _vm._v("Verify Account Status"),
      ]),
      _vm._m(0),
      _c(
        "form",
        { attrs: { id: "formRegister" } },
        [
          _c(
            "fieldset",
            { staticClass: "p_0 p-b_3" },
            [
              _c("RadioButtons", {
                attrs: {
                  inputId: "uniqueRadio",
                  options: _vm.options,
                  radioclasses:
                    " p_4 bg_black-2 br_solid br_radius br_1 br_black-2",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _vm._v(
                          " We want to make sure you don't already have an ACC account. Please click the option that applies to you to continue: "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.picked,
                  callback: function ($$v) {
                    _vm.picked = $$v
                  },
                  expression: "picked",
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.options, function (item, index) {
            return _c("transitionExpand", { key: "item_" + index }, [
              _vm.picked.value == item.value
                ? _c("div", {
                    staticClass: "m-x_4",
                    domProps: { innerHTML: _vm._s(item.content) },
                  })
                : _vm._e(),
            ])
          }),
        ],
        2
      ),
      _c("ACCMemberCare"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v(
          "We want to make sure you don't already have an ACC account. Please click the option that applies to you to continue:"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }