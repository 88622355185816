var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p_4 max-w_50 m_auto" }, [
      _c("hr"),
      _c("p", [
        _c("b", [_vm._v("Have questions or need assistance?")]),
        _vm._v(" Please contact ACC Member Care"),
      ]),
      _c("ul", { staticClass: "ul_none p-l_5" }, [
        _c("li", [
          _c("i", { staticClass: "fa fa-phone" }),
          _c("a", { attrs: { href: "tel:+18002534636" } }, [
            _vm._v(" 1-800-253-4636"),
          ]),
          _vm._v(" (Toll Free U.S. & Canada) "),
        ]),
        _c("li", [
          _c("i", { staticClass: "fa fa-phone" }),
          _vm._v(" "),
          _c("a", { attrs: { href: "tel:2023756000" } }, [
            _vm._v("202-375-6000"),
          ]),
          _vm._v(" (International)"),
        ]),
        _c("li", [
          _c("i", { staticClass: "fa fa-envelope" }),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://my.acc.org/s/ContactUsMemberCare?language=en_US",
                target: "_blank",
              },
            },
            [_vm._v("Contact Us at Member Care")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }