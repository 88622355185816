var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        staticClass:
          "modal modal-fade modal-mask z_5 bg_black-5 fixed t_0 l_0 r_0 b_0",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "modal-wrapper h_100 absolute m_auto overflow_auto p_4  t_0 l_0 r_0 b_0",
            on: { onClick: _vm.onClose },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "modal-container  shadow_3 max-w_3 m_auto p-x_4 p-y_3 font_ui transition bg_white relative br_solid br-t_3 br_primary-n1",
                class: "br_" + _vm.corner + " " + _vm.maxWidthClasses,
              },
              [
                _c(
                  "btn",
                  {
                    staticClass: "absolute t_0 r_0 l_auto",
                    attrs: {
                      size: "small",
                      corner: "square",
                      shadow: false,
                      type: "button",
                    },
                    on: { onClick: _vm.onClose },
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "font_3 font_bold font_display m-x_2 m-x_3:md m-y_2 m-y_4:md modal-header p-y_2 p-y_4:sm",
                  },
                  [
                    _vm._t("header", function () {
                      return [_vm._v("default header")]
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "modal-body font_0 m-x_2 m-x_3:md p-x_0 p-y_2 p-y_4:sm",
                  },
                  [
                    _vm._t("default", function () {
                      return [_vm._v("default body")]
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "modal-footer p-x_0  m-b_n3 " },
                  [
                    _vm._t("footer", function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "p-x_2 p-x_3:md p-x_4:lg clear_fix" },
                          [
                            _c(
                              "Btn",
                              {
                                staticClass: "float_right ",
                                attrs: {
                                  size: "small",
                                  type: "button",
                                  state: "secondary",
                                },
                                on: { onClick: _vm.onClose },
                              },
                              [_vm._v(_vm._s(_vm.cancelText))]
                            ),
                          ],
                          1
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }