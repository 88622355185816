var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Question", {
    staticClass: "max-w_65",
    attrs: {
      id: _vm.id,
      icon: _vm.icon,
      postLabel: _vm.postLabel,
      required: _vm.required,
      state: _vm.thisState,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function () {
            return [
              _vm._t("default", function () {
                return [_vm._v("Email")]
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "input",
          fn: function () {
            return [
              _c("div", { staticClass: "select-wrapper w_100 relative" }, [
                _c(
                  "select",
                  {
                    staticClass: "br_2 p-y_2 br_solid flex_auto p-l_4 lh_3",
                    class: _vm.inputStyles,
                    attrs: {
                      id: "input_" + _vm.id,
                      name: "input_" + _vm.id,
                      type: _vm.type,
                      required: _vm.required,
                      disabled: _vm.thisState == "disabled",
                    },
                    domProps: { value: _vm.value },
                    on: {
                      input: function ($event) {
                        return _vm.onInputselection($event)
                      },
                    },
                  },
                  [
                    _c(
                      "option",
                      { attrs: { disabled: "", hidden: "", value: "none" } },
                      [_vm._v(_vm._s(_vm.placeholder))]
                    ),
                    _vm._l(_vm.options, function (option, index) {
                      return _c("option", {
                        key: "option_" + index,
                        domProps: {
                          value: _vm.optionValue(option),
                          innerHTML: _vm._s(_vm.optionLabel(option)),
                        },
                      })
                    }),
                  ],
                  2
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "requiredAlertMessage",
          fn: function () {
            return [_vm._t("requiredAlertMessage")]
          },
          proxy: true,
        },
        {
          key: "alertMessage",
          fn: function () {
            return [_vm._t("alertMessage")]
          },
          proxy: true,
        },
        {
          key: "warningMessage",
          fn: function () {
            return [_vm._t("warningMessage")]
          },
          proxy: true,
        },
        {
          key: "successMessage",
          fn: function () {
            return [_vm._t("successMessage")]
          },
          proxy: true,
        },
        {
          key: "infoMessage",
          fn: function () {
            return [_vm._t("infoMessage")]
          },
          proxy: true,
        },
        {
          key: "accentMessage",
          fn: function () {
            return [_vm._t("accentMessage")]
          },
          proxy: true,
        },
        {
          key: "hint",
          fn: function () {
            return [_vm._t("hint")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }