<template>
  <div class="p_4:md max-w_50 m_auto">
      <div v-if="!duplicateuserexists">
        <div>
          <h1 class="c_acc font_6 text_center">Create Free Account</h1>
          <p>Please note that creating a free ACC account does not make you a member of the ACC or give you access to member-only content, but it does allow you to register for events, purchase ACC products, and use promo codes for discounts.</p>
        </div>
        <div v-if="ErrorCode =='3'" class="alert alert-alert show" role="alert">
          <div class="relative">
            <div class="flex">
              <div class="font_10 text_center p_3 justify_center self_center">
                <i class="far fa-exclamation-triangle"></i>
              </div>
              <div class="flex_auto p_4 p-y_2 self_center">
                <span v-html="ErrorMessage"></span>
              </div>
            </div>
          </div>
        </div>  
        <form id="formForgotPassword" class="m_auto" novalidate v-on:submit.prevent="onSubmit">
          <!--Step1-->
          <div v-show="step==1">
            <div class="text_center">
              <h3 class="m-l_1">Step 1 of 2</h3>
            </div>
            <div class="grid gap-x_5 gap-y_3 columns_2:md">
              <InputText class="m-b_4" :inputId="'firstname'" :type="'text'" v-model="RegUser.FirstName" :required=true :state="firstnameState" @onStateChange="updateInputState">
                <template v-slot:default>
                  First Name
                </template>
                <template v-slot:requiredAlertMessage>
                  Please enter First Name.
                </template>
              </InputText>
              <InputText class="m-b_4" :inputId="'lastname'" :type="'text'" v-model="RegUser.LastName" :required=true :state="lastnameState" @onStateChange="updateInputState">
                <template v-slot:default>
                  Last Name
                </template>
                <template v-slot:requiredAlertMessage>
                  Please enter Last Name.
                </template>
              </InputText>
              <div>
                <InputEmail class="m-b_4" :inputId="'email'" :type="'email'" v-model="RegUser.UserName" :required=true :state="emailState" @onStateChange="updateEmailState">
                  <template v-slot:default>
                    Email
                  </template>
                  <template v-slot:requiredAlertMessage>
                    Please enter Email.
                  </template>
                  <template v-slot:alertMessage>
                    <span v-if="!emailexists">A valid email address format is required.</span>
                    <span v-if="emailexists">This email is already in our system. If you have an ACC account, log in <router-link to="/">here</router-link>.</span>
                  </template>
                  <template v-slot:warningMessage>
                    Exists
                  </template>
                </InputEmail>
                <p class="input-description m_0">You will use this for login</p>
              </div>
              <SelectProfession id="input_professional" class="m-b_4" :professions="professions" :professionalAlert="professionalState" @input="updateProfession" @cancelselection="cancelProfession" />
              <SetPassword class="display_contents" :pwd1intialvalue="RegUser.Password" :pwd2intialvalue="RegUser.Password" @passwordChanged="updatePassword" />
            </div>
          
            <div class="text_center" @click.prevent="forwardstep">
              <Btn :isDisabled="isDisabled" :size="'medium'">
                <span class="p-r_3">Continue</span>
                <i v-if="loading" id="btnSpinner" class="spinner fa fa-spinner fa-spin self_center"></i>
              </Btn>
            </div>

          </div>
          <!--Step2-->
          <div v-show="step==2">
            <div class="row relative clear_fix m-b_4">
              <div class="col-md-5" @click.prevent="backtostep">
                <Btn :size="'small'" class="absolute">
                  <i class="far fa-arrow-left self_center m-r_3"></i> Back to Step 1
                </Btn>
              </div>
              <div class="col-md-7" ref="designationstep2">
                <h3 class="m-l_1">Step 2 of 2</h3>
              </div>
            </div>
            <!--<div class="relative clear_fix m-b_4">
    <div class="col-md-5" @click="backtostep">
      <Btn :size="'small'" class="absolute"><i class="far fa-arrow-left self_center m-r_3"></i> Back</Btn>
    </div>

    <h3 class="text_center m-y_2">Step 2 of 2</h3>
  </div>-->
            <div class="grid gap-x_5 gap-y_3  columns_2:md">
              <!-- <a href="javascript:void(0)" @click="backtostep">Back to Step 1</a>-->
              <InputSelect class="m-b_4" tabindex="0" :inputId="'designation'" v-model="RegUser.Designation" :options="designations" :state="designationState" :required=true @onStateChange="updateInputState">
                <template v-slot:default>
                  Designation
                </template>
                <template v-slot:requiredAlertMessage>
                  Please choose Designation.
                </template>
              </InputSelect>
              <InputSelect class="m-b_4" :inputId="'country'" v-model="RegUser.Country" :options="countries" :required=true :state="countryState" :placeholder="'Select Country'" @onStateChange="updateInputState">
                <template v-slot:default>
                  Country
                </template>
                <template v-slot:requiredAlertMessage>
                  Please choose Country.
                </template>
              </InputSelect>
              <InputText class="m-b_4" :inputId="'street'" :type="'text'" v-model="RegUser.WorkAddress" :required=true :state="streetState" @onStateChange="updateInputState">
                <template v-slot:default>
                  Street
                </template>
                <template v-slot:requiredAlertMessage>
                  Please enter Street.
                </template>
              </InputText>
              <InputText class="m-b_4" :inputId="'city'" :type="'text'" v-model="RegUser.City" :required=true :state="cityState" @onStateChange="updateInputState">
                <template v-slot:default>
                  City
                </template>
                <template v-slot:requiredAlertMessage>
                  Please enter City.
                </template>
                <template v-slot:alertMessage>
                  City is Invalid.
                </template>
              </InputText>
              <InputSelect v-if="isdomestic" class="m-b_4" :inputId="'state'" v-model="RegUser.StateProvince" :required="isdomestic" :options="states" :state="stateState" @onStateChange="updateInputState">
                <template v-slot:default>
                  State/Province
                </template>
                <template v-slot:requiredAlertMessage>
                  Please choose State/Province.
                </template>
              </InputSelect>
              <InputText v-if="!isdomestic" class="m-b_4" :inputId="'state'" :type="'text'" v-model="RegUser.StateProvince" :state="stateState" @onStateChange="updateInputState">
                <template v-slot:default>
                  State/Province
                </template>
                <template v-slot:requiredAlertMessage>
                  Please enter State/Province.
                </template>asd
              </InputText>
              <InputZip class="m-b_4" :inputId="'zip'" :type="'text'" :required="isdomestic" v-model="RegUser.Zip" :state="zipState" @onStateChange="updateInputState">
                <template v-slot:default>
                  ZIP/Postal Code
                </template>
                <template v-slot:requiredAlertMessage>
                  Please enter Zip/Postal Code.
                </template>
                <template v-slot:alertMessage>
                  <span>Zip/Postal Code is Invalid.</span>
                </template>
                <template v-slot:warningMessage>
                  Exists
                </template>
              </InputZip>
            </div>
            <div class="m-b_4">
              <CheckboxInput v-model="agreement">  </CheckboxInput>
            </div>
            <div class="m-b_4" id="recaptchaid">
              <vue-recaptcha :sitekey="RecaptchPublicKey" @verify="onVerify" :load-recaptcha-script="true" @expired="onCaptchaExpired"></vue-recaptcha>
            </div>
            <div class="text_center m-b_4" @click="onDisabledCheck('all')">
              <Btn :isDisabled="isDisabled" :size="'medium'">
                <span class="p-r_3">Create Free Account</span>
                <i v-if="loading" id="btnSpinner" class="spinner fa fa-spinner fa-spin self_center"></i>
              </Btn>
            </div>
          </div>
          <div class="text_center m-t_4">
            Already have an ACC account? <a v-bind:href=loginurl> Log In. </a>
          </div>
        </form>
      </div>
        <div v-if="duplicateuserexists">
        <IsThisYou :options="duplicateusers" :targeturl="returnurl" @freeaccount ="checkduplicateuser"></IsThisYou>
      </div>
    <ACCMemberCare></ACCMemberCare>
  </div>
</template>

<script>
  import ACCMemberCare from "./ACCMemberCare.vue"
  import InputText from '@/components/BasicForms/Input.Text.vue'
  import Btn from '@/components/subComponents/Btn.vue'
  import InputEmail from '@/components/BasicForms/Input.Email.vue'
  import SelectProfession from '@/components/BasicForms/SelectProfession.Wrapper.vue'
  import SetPassword from '@/components/BasicForms/SetPassword.vue'
  import InputSelect from '@/components/BasicForms/Input.Select.vue'
  import InputZip from '@/components/BasicForms/Input.Zip.vue'
  import CheckboxInput from "@/components/BasicForms/Input.Checkbox.vue"
  import IsThisYou from './IsThatYou.vue'
  import axios from 'axios'
  import { VueRecaptcha } from 'vue-recaptcha'; 
  export default {
    name: 'Registration',
    components: {
      InputText,
      Btn,
      ACCMemberCare,
      axios,
      InputEmail,
      SelectProfession,
      SetPassword,
      InputSelect,
      InputZip,
      IsThisYou,
      CheckboxInput,
      VueRecaptcha
    },
    data: () => ({
      loginurl: document.getElementById("main-content").getAttribute("data-saleforcelogin"),
      loading: false,
      //profession selection data
      RegUser: {
        FirstName: '',
        LastName: '',
        UserName: '',
        Password: null,
        Designation: '',
        StateProvince: '',
        WorkAddress: '',
        City: '',
        Country: '',
        Zip: '',
        ProfessionalTypeCode:'',
        ProfessionalSubTypeCode: '',
        HasSecurrityQuestionAnswers: true,
        GoogleToken:''
      },
      professions: [],
      designations: [],
      countries: [],
      states:[],
       selectedProfession: {},
      // selectedProfession: [null, "RESIDENTSUR"],
      //field value bindings
      professionalState: false,
      firstnameState: null,
      lastnameState: null,
      emailState: null,
      designationState: null,
      countryState: null,
      zipState: null,
      timeout: null,
      streetState: null,
      cityState: null,
      stateState: null,
      emailexists: false,
      step: 1,
      isdomestic: false,
      firstcheck: true,
      duplicateuserexists: false,
      duplicateusers: [],
      RegDuplicateUser: false,
      ErrorCode: '',
      ErrorMessage: '',
      success: false,
      IsCaptchChecked :false,
      agreement: { selected: false, label: '<span>I have read and understand the <a href=http://www.acc.org/footer-pages/registered-user-agreement  target=_blank>registered user agreement</a> and agree to be bound by all of the <a href=http://www.acc.org/footer-pages/terms-and-conditions target=_blank>terms</a>.</span>' },
      returnurl:''
    }),
     
    computed: {
      isDisabled() {
        return false /// this.checkdisabled()

      }
    },
    methods: {
      onDisabledCheck(eventTargetDOM) {
        if (this.step == 1) {
           if (eventTargetDOM == 'all' || eventTargetDOM == 'input_firstname') {
             if (this.RegUser.FirstName == '') { this.firstnameState = 'requiredAlert';} else { this.firstnameState = ''; }
          }
          if (eventTargetDOM == 'all' || eventTargetDOM == 'input_lastname') {
            if (this.RegUser.LastName == '') { this.lastnameState = 'requiredAlert';} else { this.lastnameState = ''; }
          }
          if (eventTargetDOM == 'all' || eventTargetDOM == 'input_pwd1') {
            if (this.RegUser.Password == '') { this.passwordState = 'requiredAlert';} else { this.passwordState = ''; }
          }
          if (eventTargetDOM == 'all' || eventTargetDOM == 'input_email') {
            if (this.RegUser.UserName == '') { this.emailState = 'requiredAlert'; } else { this.emailState = ''; }
          }
          
          if (Object.keys(this.selectedProfession).length == 0 && this.firstcheck)
          { this.professionalState = true }
          else { this.professionalState = false }
           this.firstcheck=true
           
        }
        else {
          //this.designationValue && this.countryValue && this.streetValue && this.cityValue && this.stateValue && this.zipValue
          if (eventTargetDOM == 'all' || eventTargetDOM == 'input_designation') {
            if (this.RegUser.Designation == '')
            {this.designationState = 'requiredAlert';} else { this.designationState = '';}
          }
          if (eventTargetDOM == 'all' || eventTargetDOM == 'input_country') {
            if (this.RegUser.Country == '') { this.countryState = 'requiredAlert'; } else { this.countryState = ''; }
          }
          if (eventTargetDOM == 'all' || eventTargetDOM == 'input_street') {
            if (this.RegUser.WorkAddress == '') { this.streetState = 'requiredAlert'; } else { this.streetState = ''; }
          }
          if (eventTargetDOM == 'all' || eventTargetDOM == 'input_city') {
            if (this.RegUser.City == '') { this.cityState = 'requiredAlert'; } else { this.cityState = ''; }
          }
          if (this.isdomestic) {
            if (eventTargetDOM == 'all' || eventTargetDOM == 'input_state') {
              if (this.RegUser.StateProvince == '') { this.stateState = 'requiredAlert'; } else { this.stateState = ''; }
            }
           // if (this.isUSA)
              if (eventTargetDOM == 'all' || eventTargetDOM == 'input_zip') {
                if (this.RegUser.Zip == '') { this.zipState = 'requiredAlert'; } else { this.zipState = ''; }
              }
          }
           
        }
       
      },
      checkdisabled() {
        //if any state populated, or loading is true
        //NOTE: If you need more states that are not meant to block submission, you will need to make this more robust.

        if (this.step == 1) {
          if (this.loading || this.firstnameState || this.lastnameState || this.emailState || !this.RegUser.Password  ) {
           
            return true;
          }
          if (this.RegUser.FirstName && this.RegUser.LastName && this.RegUser.UserName && this.RegUser.Password && Object.keys(this.selectedProfession).length) {
            return false;
          }
          if (Object.keys(this.selectedProfession).length == 0 && !this.firstcheck ) {
            this.professionalState = true
            return true;
          }
          return true;
        }
        else {
          if (this.isdomestic) {
            //const zipvalidval = (this.isUSA == true ? this.zipValue : true)
            if (this.loading || this.designationState || this.countryState || this.streetState || this.cityState || this.stateState || this.zipState) {
              return true;
            }
            if (this.RegUser.Designation && this.RegUser.Country && this.RegUser.WorkAddress && this.RegUser.City && this.RegUser.StateProvince && this.RegUser.Zip && this.agreement.selected && this.IsCaptchChecked) {
              return false;
            }
            return true;
          }
          else {
            if (this.loading || this.designationState || this.countryState || this.streetState || this.cityState) {
              return true;
            }
            if (this.RegUser.Designation && this.RegUser.Country && this.RegUser.WorkAddress && this.RegUser.City && this.agreement.selected && this.IsCaptchChecked) {
              return false;
            }
            return true;
          }
        }
        return true
      },
      updateProfession(profession) {
        this.selectedProfession =profession;
           this.professionalState = false
      },
      cancelProfession(profession) {
        if (Object.keys(profession).length == 0)
          this.professionalState = true
        else
          this.professionalState = false
      },
      updatePassword(v) {
        this.RegUser.Password = v;
      },
      isUsernameExists(val) {
        var postdata= {
          UserName: val
        }
        axios
          .post('/Validation/IsAvailable', postdata)
          .then((response) => {
            if (response.data) {
              console.log(response.data)
              this.emailexists = response.data.result
              if (this.emailexists) {
                this.emailState = 'alert'
                this.checkdisabled()
              }
            }
            else {

            }
          }).catch((error) => {

          });
      },
      isCityValid(val) {
        var postdata = {
          City: val,
          Country: this.RegUser.Country
        }
        axios
          .post('/Validation/IsValidCityCountry', postdata)
          .then((response) => {
            if (response.data) {
              console.log(response.data)
              if (!response.data.result) {
                this.cityState = 'alert'
                this.checkdisabled()
              }
            }
            else {

            }
          }).catch((error) => {

          });
      },
      isZipValid(val) {
        var postdata = {
          Zip: val,
          Country: this.RegUser.Country
        }
        if (this.RegUser.Country == 'USA') {
          axios
            .post('/Validation/IsValidZipCodeCountry', postdata)
            .then((response) => {
              if (response.data) {
                console.log(response.data)
                if (!response.data.result) {
                  this.zipState = 'alert'
                  this.checkdisabled()
                }
              }
              else {

              }
            }).catch((error) => {

            });
        }
        else if (this.RegUser.Country == 'CAN') {
          const ca = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY][ -]?\d[ABCEGHJKLMNPRSTVXY]\d$/i);
          if (ca.test(val)) {
            this.zipState = ''
          }
          var us = new RegExp("^\\d{5}(-{0,1}\\d{4})?$")
          if (us.test(val)) {
            this.zipState ='alert'
          }
          this.checkdisabled()
        }
      },
      updateEmailState(b,val) {
        //The existing email component emits empty string, but we're going to set it to null here instead for easier conditional checking.
        //Doing it here avoids having to change the email component for now.
        const state = (b == "" ? null : b);
        this.emailexists = false
        this.emailState = state;
        var self=this
        if (state == null) {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(function () {
            // enter this block of code after 1 second
            // handle stuff, call search API etc.
            self.isUsernameExists(val)
           }, 2000);
        }
      },
      updateInputState(b) {
        const state = (b.state == "" ? null : b.state);
        if (b.target.id == 'input_firstname') {
          this.firstnameState = state;
        }
        if (b.target.id == 'input_lastname') {
          this.lastnameState = state;
        }
        if (b.target.id == 'input_designation') {
          this.designationState = state;
        }
        if (b.target.id == 'input_country') {
          this.countryState = state;
          this.checkisDomestic(b.target.value)
          this.RegUser.WorkAddress = ''
          this.RegUser.City = ''
          this.RegUser.StateProvince = ''
          this.RegUser.Zip = ''
          this.streetState = ''
          this.cityState = ''
          this.stateState = ''
          this.zipState = ''
         }
        if (b.target.id == 'input_zip') {
          if (this.isdomestic) {
            this.zipState = state
          }
          else
            this.zipState = ''
          if (state == null && this.isdomestic) {
            clearTimeout(this.timeout);
            var self = this
            this.timeout = setTimeout(function () {
              // enter this block of code after 1 second
              // handle stuff, call search API etc.
              self.isZipValid(b.target.value)

            }, 2000);
          }
          if (state == 'alert' && this.isdomestic && this.RegUser.Country == 'CAN') {
            clearTimeout(this.timeout);
            var self = this
            this.timeout = setTimeout(function () {
              self.isZipValid(b.target.value)

            }, 2000);
          }
        }
        if (b.target.id == 'input_state') {
          if (this.isdomestic)
            this.stateState = state
          else
            this.stateState = '';
        }
        if (b.target.id == 'input_street') {
          this.streetState = state;
        }
        if (b.target.id == 'input_city') {
          this.cityState = state;
          if (state == null && this.isdomestic) {
            clearTimeout(this.timeout);
            var self = this
            this.timeout = setTimeout(function () {
              // enter this block of code after 1 second
              // handle stuff, call search API etc.
              self.isCityValid(b.target.value)
              
            }, 2000);
          }
        }
      },
      checkisDomestic(value) {
        if (value == 'USA' || value == 'CAN') {
          this.isdomestic = true
          this.loadstates(value)
         // this.isUSA = (value =='USA')? true:false
        }
        else
          this.isdomestic =false
      },
      onSubmit() {
        this.ErrorCode = ''
        this.ErrorMessage=''
       // this.$refs.designationstep2.focus()
        if (this.RegUser) {
          this.RegUser.ProfessionalTypeCode = this.selectedProfession[1]
          this.RegUser.ProfessionalSubTypeCode = (this.selectedProfession[2] !== undefined || this.selectedProfession[2] != null) ? this.selectedProfession[2] : ''
          this.loading = true
          var postdata = {
            user: this.RegUser,
            IsDuplicateUser: this.RegDuplicateUser
          }
            axios
              .post('/Registration/Registration'+ this.returnurl, postdata)
              .then((response) => {
                if (response.data) {
                  $('#recaptchaid').hide()
                  if (response.data.hasOwnProperty('Code'))
                  {
                    if (response.data.Code == 1) {
                    
                      this.success = true
                      console.log('Account Created Successfully ')
                    
                    }
                    else if (response.data.Code == 2) {
                      this.duplicateusers = response.data.list
                      this.duplicateuserexists = true
                    }
                    else (response.data.Code == 3)
                    {
                      this.ErrorCode = response.data.Code
                      this.ErrorMessage = response.data.ErrorMessage
                      $('#recaptchaid').show()
                    }
                  }
                  if (response.data.hasOwnProperty('oAuth')) // if its oAuth then get the url and send it.
                  {
                  
                    if (response.data.Error != '' && response.data.oAuth.toString() === 'error') {
                      this.ErrorMessage = response.data.Error.toString()
                      this.ErrorCode = 3
                      this.loading = false
                      $('#recaptchaid').show()
                    }
                    else {
                      this.success = true
                      this.checkdisabled = true
                      window.location.href = response.data.URL.toString();
                     
                    }
                   }
                  else { // if its POSTSP refresh the page then automatically goes to vendors page
                    if (!response.data.hasOwnProperty('Status')) {
                      this.success = true
                      this.checkdisabled = true
                      window.location.href = response.data.Url
                      //  location.reload()
                     
                    }
                  }
                  this.loading = false
                 // $('#recaptchaid').show()
                }

              }).catch((error) => {
                this.loading = false
                $('#recaptchaid').show()
              });
          }
      },
   
      checkduplicateuser(data) {
        this.RegDuplicateUser = true
        this.onSubmit()
      },
     
      loadprofessioanl() {
        axios
          .post('/Registration/GetProfessional')
          .then((response) => {
            if (response.data) {
              this.professions = response.data.result
            }
            else {
               
            }
          }).catch((error) => {
             
          });
      },
      loaddesignations() {
        axios
          .post('/Registration/GetDesignations')
          .then((response) => {
            if (response.data) {
              this.designations = response.data.result
            }
            else {

            }
          }).catch((error) => {

          });
      },
      loadcountries() {
        axios
          .post('/Registration/GetCountries')
          .then((response) => {
            if (response.data) {
              this.countries = response.data.result
            }
            else {

            }
          }).catch((error) => {

          });
      },
      loadstates(val) {
        var postdata = {
          countrycode: val
        }
        axios
          .post('/Registration/GetStates',postdata)
          .then((response) => {
            if (response.data) {
              this.states = response.data.result
            }
            else {

            }
          }).catch((error) => {

          });
      },
      backtostep() {
        this.step = 1
       // this.checkdisabled()
      },
      forwardstep() {
        if (!this.checkdisabled())
          this.step = 2
        else
          this.onDisabledCheck('all')
      },
      closingbrowser(e) {
        if (!this.success) {
          e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
          // Chrome requires returnValue to be set
          e.returnValue = '';
        }
       },
      brwoserback(to, from, next) {
        const answer = window.confirm('Changes you made will not be saved.')
        if (answer) {
          next()
        } else {
          next(false)
        }
      },
      onVerify: function (response) {
        this.IsCaptchChecked = true
        this.RegUser.GoogleToken=response
      },
      onCaptchaExpired: function () {
        if (this.loading == false) {
          this.IsCaptchChecked = false
          this.RegUser.GoogleToken = ''
          //if (this.success == false)
          //$('#recaptchaid').show()
        }
        else {
          $('#recaptchaid').hide()
        }
      },
    },
    beforeMount: function () {
      this.loadprofessioanl();
      this.loaddesignations();
      this.loadcountries();
      //if (this.$route.query.returnUrl != undefined && this.$route.query.returnUrl != '')
      //  this.returnurl = "?returnUrl=" + this.$route.query.returnUrl
      //console.log(this.returnurl)
      if (this.$route.fullPath != '' && this.$route.fullPath.split('?')) {
        if (this.$route.fullPath.split('?').length > 0) {
          this.returnurl = (this.$route.fullPath.split('?')[1] != undefined ? '?' + this.$route.fullPath.split('?')[1] : '')
        }
      }
      console.log(this.returnurl)
    },
    created() {
      window.addEventListener('beforeunload', this.closingbrowser)
    },
    beforeRouteLeave(to, from, next) {
       this.brwoserback(to, from, next)
    },
   
  }
</script>
