var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("div", { staticClass: "flex flex_row" }, [
        _c(
          "label",
          {
            staticClass: "flex flex_inline flex_nowrap",
            class: _vm.LabelStyles,
            attrs: { for: "radio_" + _vm.checkboxLabelCodeSafe },
          },
          [
            _c("input", {
              staticClass: "inline-block p-r_3 self_center",
              attrs: {
                type: "radio",
                name: _vm.name,
                id: "radio_" + _vm.checkboxLabelCodeSafe,
                required: _vm.required,
                disabled: _vm.state == "disabled",
              },
              domProps: { value: _vm.value, checked: _vm.checkboxValue },
              on: {
                change: function ($event) {
                  return _vm.onInput($event.target.checked)
                },
              },
            }),
            _vm.icon
              ? _c("ValueIcon", {
                  staticClass: "flex_none p-x_3 p-y_0",
                  attrs: {
                    state: _vm.state,
                    icon: _vm.icon,
                    inputNameTarget: "id",
                  },
                })
              : _vm._e(),
            _c("span", { staticClass: "self_center m-l_4 lh_2" }, [
              _vm._v(_vm._s(_vm.checkboxLabel)),
            ]),
            _vm.required
              ? _c(
                  "span",
                  { staticClass: "required-holder flex_shrink font_n5" },
                  [
                    _c("i", {
                      staticClass:
                        "fas fa-asterisk c_warning vertical-align_top",
                    }),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.postLabel
          ? _c(
              "span",
              {
                staticClass:
                  "\r\n            font_medium\r\n            flex_none\r\n            m-l_3\r\n            self_center\r\n        ",
                class: _vm.inputPrePostStyles,
                on: {
                  click: function ($event) {
                    return _vm.$emit("onClickPostLabel")
                  },
                },
              },
              [_c("span", { domProps: { innerHTML: _vm._s(_vm.postLabel) } })]
            )
          : _vm._e(),
      ]),
      _vm.inputState == "requiredAlert"
        ? _c("messageHolder", { attrs: { state: "alert" } }, [
            _vm._v("This is required."),
          ])
        : _vm._e(),
      _c("div", { staticClass: "opacity_7 font_italic" }, [_vm._t("hint")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }