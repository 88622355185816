var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p_4 max-w_50 m_auto" }, [
    _vm._m(0),
    _vm._m(1),
    _c(
      "fieldset",
      { staticClass: "p_0 p-b_3" },
      [
        _c("RadioButtons", {
          attrs: {
            inputId: "uniqueRadio",
            title: _vm.title,
            options: _vm.options,
            radioclasses: " p_4 bg_black-2 br_solid br_radius br_1 br_black-2",
          },
          model: {
            value: _vm.picked,
            callback: function ($$v) {
              _vm.picked = $$v
            },
            expression: "picked",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "text_center m-b_4" },
      [
        _c("span", {
          model: {
            value: _vm.picked.value,
            callback: function ($$v) {
              _vm.$set(_vm.picked, "value", $$v)
            },
            expression: "picked.value",
          },
        }),
        _c("span", {
          model: {
            value: _vm.picked,
            callback: function ($$v) {
              _vm.picked = $$v
            },
            expression: "picked",
          },
        }),
        _vm._l(_vm.options, function (item, index) {
          return _c("transitionExpand", { key: "item_" + index }, [
            _vm.picked.value == item.value
              ? _c("div", [
                  _vm.picked.value == "notme"
                    ? _c(
                        "div",
                        {
                          staticClass: "m-x_4",
                          on: { click: _vm.createaccount },
                        },
                        [
                          _c("Btn", { attrs: { size: "medium" } }, [
                            _c("span", { staticClass: "p-r_3" }, [
                              _vm._v("Create Free Account"),
                            ]),
                            _vm.loading
                              ? _c("i", {
                                  staticClass:
                                    "spinner fa fa-spinner fa-spin self_center",
                                  attrs: { id: "btnSpinner1" },
                                })
                              : _vm._e(),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.picked.value != "notme"
                    ? _c("div", { staticClass: "m-x_4" }, [
                        _c(
                          "div",
                          { staticClass: "text_center m-t_4" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  " flex_column p-y_3 lh_2 p-x_4 font_1 font_2:md ease_out transition_1 f:outline_none text_center br_none inline-block w_auto font_medium font_ui  c_white h:c_white h:bg_primary-n2 br_primary-n3 bg_primary  shadow_overlap-light  undefined cursor_pointer  br_radius ",
                                attrs: { to: "/" },
                              },
                              [_vm._v("Log In")]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.picked.value != "notme"
                    ? _c(
                        "div",
                        { staticClass: "m-x_4", on: { click: _vm.LogInclick } },
                        [
                          _c("Btn", { attrs: { size: "medium" } }, [
                            _c("span", { staticClass: "p-r_3" }, [
                              _vm._v("Log In"),
                            ]),
                            _vm.loading
                              ? _c("i", {
                                  staticClass:
                                    "spinner fa fa-spinner fa-spin self_center",
                                  attrs: { id: "btnSpinner1" },
                                })
                              : _vm._e(),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h1", { staticClass: "c_acc font_6 text_center" }, [
        _vm._v("Is This You?"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "alert alert-secondary", attrs: { role: "alert" } },
      [
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            {
              staticClass:
                "font_10 text_center p_3 justify_center self_center lh_0",
            },
            [_c("i", { staticClass: "far fa-exclamation-circle" })]
          ),
          _c("div", { staticClass: "flex_auto p_4 p-y_2 self_center" }, [
            _c(
              "h4",
              {
                staticClass:
                  "alert-heading  font-size_up-1 font_display  font_medium",
              },
              [
                _vm._v(
                  "Our records indicate that you may already have an account."
                ),
              ]
            ),
            _c("p", [
              _vm._v(
                'Please verify if any of the following accounts belong to you. If no, click "No, I don\'t have an account" to create your new account.'
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }