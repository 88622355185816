var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p_4 max-w_50 m_auto" }, [
    _vm.SecurityUser.StatusCode < "3"
      ? _c("div", [
          _vm._m(0),
          _vm.SecurityUser.StatusCode != "" &&
          (_vm.SecurityUser.StatusCode == "1" ||
            _vm.SecurityUser.StatusCode == "2") &&
          _vm.SecurityUser.StatusMessage != ""
            ? _c(
                "div",
                {
                  staticClass: "alert alert-alert show",
                  attrs: { role: "alert" },
                },
                [
                  _c("div", { staticClass: "relative" }, [
                    _c("div", { staticClass: "flex" }, [
                      _vm._m(1),
                      _c(
                        "div",
                        { staticClass: "flex_auto p_4 p-y_2 self_center" },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.SecurityUser.StatusMessage),
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "text_center" }, [
            _c("div", { staticClass: "text_center" }, [
              _vm._m(2),
              _c("div", [_c("p", [_vm._v(" " + _vm._s(_vm.titletext))])]),
              _c("p", [
                _vm._v(
                  " Answer your questions below and verify your identity."
                ),
              ]),
              _c("p", { staticClass: "font_italic" }, [
                _vm._v("Hint: Answers are not case sensitive."),
              ]),
            ]),
          ]),
          _c(
            "form",
            {
              staticClass: "p_4 max-w_30 m_auto",
              attrs: { id: "formForgotUsername", novalidate: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c("fieldset", [
                _c(
                  "div",
                  { staticClass: "m-b_4" },
                  [
                    _c("InputText", {
                      ref: "TextInput",
                      class: "br_radius",
                      attrs: {
                        id: "sq1",
                        type: "text",
                        required: true,
                        state: _vm.sq1State,
                      },
                      on: { onStateChange: _vm.updateState },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.SecurityUser.SecurityQuestion1) +
                                    " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "requiredAlertMessage",
                            fn: function () {
                              return [
                                _vm._v(
                                  " Must answer question correctly. Please try again. "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2583643188
                      ),
                      model: {
                        value: _vm.SecurityUser.Answer1,
                        callback: function ($$v) {
                          _vm.$set(_vm.SecurityUser, "Answer1", $$v)
                        },
                        expression: "SecurityUser.Answer1",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "m-b_4" },
                  [
                    _c("InputText", {
                      class: "br_radius",
                      attrs: {
                        id: "sq2",
                        type: "text",
                        required: true,
                        state: _vm.sq2State,
                      },
                      on: { onStateChange: _vm.updateState },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.SecurityUser.SecurityQuestion2) +
                                    " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "requiredAlertMessage",
                            fn: function () {
                              return [
                                _vm._v(
                                  " Must answer question correctly. Please try again. "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        615021687
                      ),
                      model: {
                        value: _vm.SecurityUser.Answer2,
                        callback: function ($$v) {
                          _vm.$set(_vm.SecurityUser, "Answer2", $$v)
                        },
                        expression: "SecurityUser.Answer2",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "text_center",
                    on: {
                      click: function ($event) {
                        return _vm.onDisabledCheck("submit")
                      },
                    },
                  },
                  [
                    _c("Btn", { attrs: { isDisabled: _vm.isDisabled } }, [
                      _c("div", [
                        _c("span", { staticClass: "p-r_3" }, [
                          _vm._v(_vm._s(_vm.buttontext)),
                        ]),
                      ]),
                      _vm.loading
                        ? _c("i", {
                            staticClass:
                              "spinner fa fa-spinner fa-spin self_center",
                            attrs: { id: "btnSpinner" },
                          })
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c("p", { staticClass: "text_center p_4 resendemail" }, [
                  _c(
                    "a",
                    {
                      staticClass: "resendemail",
                      attrs: { id: "btnRemember", href: "javascript:void(0);" },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("I can't remember")]
                  ),
                ]),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _c("div", [_c("ACCMemberCare")], 1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text_center" }, [
      _c("h1", { staticClass: "c_acc font_6" }, [
        _vm._v(" Verify Your Identity "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "font_10 text_center p_3 justify_center self_center" },
      [_c("i", { staticClass: "far fa-exclamation-triangle" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("i", { staticClass: "far font_10 fa-lock fas c_secondary" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }