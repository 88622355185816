var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid gap-x_3 gap-y_4" },
    _vm._l(_vm.options, function (checkbox, index) {
      return _c("InputRadioBox", {
        key: "checkbox" + _vm.id + index,
        class: _vm.radioclasses,
        attrs: {
          value: checkbox,
          name: _vm.name,
          required: _vm.required,
          disabled: _vm.state == "disabled",
          index: index,
        },
        on: {
          input: function ($event) {
            return _vm.onChange($event)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }