var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Question", {
    attrs: {
      id: _vm.id,
      icon: _vm.icon,
      postLabel: _vm.postLabel,
      required: _vm.required,
      state: _vm.thisState,
    },
    on: {
      onClickPostLabel: function ($event) {
        return _vm.$emit("onClickPostLabel")
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function () {
            return [_vm._t("default")]
          },
          proxy: true,
        },
        {
          key: "input",
          fn: function () {
            return [
              _c("input", {
                ref: "input",
                staticClass:
                  "br_2 p-y_2 br_solid flex_auto p-l_4 lh_3 br_radius",
                class: _vm.inputStyles,
                attrs: {
                  id: "input_" + _vm.id,
                  name: "input_" + _vm.id,
                  type: _vm.type,
                  required: _vm.required,
                  placeholder: _vm.placeholder,
                  disabled: _vm.thisState == "disabled",
                },
                domProps: { value: _vm.value },
                on: {
                  input: function ($event) {
                    return _vm.onInput($event)
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("keyupenter", $event.target.value)
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "requiredAlertMessage",
          fn: function () {
            return [_vm._t("requiredAlertMessage")]
          },
          proxy: true,
        },
        {
          key: "alertMessage",
          fn: function () {
            return [_vm._t("alertMessage")]
          },
          proxy: true,
        },
        {
          key: "warningMessage",
          fn: function () {
            return [_vm._t("warningMessage")]
          },
          proxy: true,
        },
        {
          key: "successMessage",
          fn: function () {
            return [_vm._t("successMessage")]
          },
          proxy: true,
        },
        {
          key: "infoMessage",
          fn: function () {
            return [_vm._t("infoMessage")]
          },
          proxy: true,
        },
        {
          key: "accentMessage",
          fn: function () {
            return [_vm._t("accentMessage")]
          },
          proxy: true,
        },
        {
          key: "hint",
          fn: function () {
            return [_vm._t("hint")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }