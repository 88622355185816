var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Question", {
    staticClass: "max-w_65",
    attrs: {
      inputId: _vm.inputId,
      icon: _vm.icon,
      postLabel: _vm.postLabel,
      required: _vm.required,
      state: _vm.thisState,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function () {
            return [
              _vm._t("default", function () {
                return [_vm._v("Email")]
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "input",
          fn: function () {
            return [
              _c("input", {
                ref: "input",
                staticClass:
                  "br_2 p-y_2 br_solid flex_auto p-l_4 lh_3 br-bl_square br-tl_square br_radius",
                class: _vm.inputStyles,
                attrs: {
                  id: _vm.inputId,
                  name: _vm.inputId,
                  type: _vm.inputType,
                  required: _vm.required,
                  disabled: _vm.thisState == "disabled",
                  placeholder: _vm.placeholder,
                },
                domProps: { value: _vm.value },
                on: {
                  input: function ($event) {
                    return _vm.onInput($event)
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "requiredAlertMessage",
          fn: function () {
            return [_vm._t("requiredAlertMessage")]
          },
          proxy: true,
        },
        {
          key: "alertMessage",
          fn: function () {
            return [
              _vm._t("alertMessage", function () {
                return [_vm._v("This is not a valid email.")]
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "warningMessage",
          fn: function () {
            return [_vm._t("warningMessage")]
          },
          proxy: true,
        },
        {
          key: "successMessage",
          fn: function () {
            return [_vm._t("successMessage")]
          },
          proxy: true,
        },
        {
          key: "infoMessage",
          fn: function () {
            return [_vm._t("infoMessage")]
          },
          proxy: true,
        },
        {
          key: "accentMessage",
          fn: function () {
            return [_vm._t("accentMessage")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }