var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "question font_ui" }, [
    _vm.hasLabel
      ? _c(
          "label",
          {
            staticClass:
              "\n\t\t\tlabel-holder\n\t\t\tflex\n\t\t\tfont-size_up\n\t\t\tfont_display font_medium\n\t\t\tp-y_2\n\t\t",
            class: [
              {
                c_alert: _vm.state == "alert",
              },
              {
                c_warning: _vm.state == "warning",
              },
            ],
            attrs: { for: "input_" + _vm.id },
          },
          [
            _c(
              "span",
              { staticClass: "text cell flex_shrink" },
              [_vm._t("default")],
              2
            ),
            _vm.required
              ? _c(
                  "span",
                  { staticClass: "required-holder flex_shrink font_n5" },
                  [
                    _c("i", {
                      staticClass:
                        "fas fa-asterisk c_warning vertical-align_top",
                    }),
                  ]
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _c(
      "fieldset",
      {
        staticClass:
          "\n\t\t\tflex flex_row:md\n\t\t\tgap-x_4\n\t\t\tflex_wrap\n\t\t\tbr_solid br_radius br_2\n\t\t\tp-b_4\n\t\t\trelative\n\t\t",
        class: _vm.fieldSetCSS.borderColor,
      },
      [
        _vm.hasLegend
          ? _c(
              "legend",
              {
                staticClass:
                  "\n\t\t\t\tabsolute\n\t\t\t\tr_auto\n\t\t\t\tw_auto\n\t\t\t\tt_n3\n\t\t\t\tm-t_n2\n\t\t\t\tp-x_3\n\t\t\t\tl_2\n\t\t\t\tlh_0\n\t\t\t\t \n\t\t\t\tbg_white\n\t\t\t\tp-x_4\n\t\t\t\tfont_ui font_medium\n\t\t\t",
                class: _vm.fieldSetCSS.fontColor,
              },
              [
                _vm.icon
                  ? _c("ValueIcon", {
                      staticClass: "flex_none p-x_3 p-y_0",
                      attrs: {
                        state: _vm.state,
                        icon: _vm.icon,
                        inputNameTarget: "id",
                      },
                    })
                  : _vm._e(),
                _vm._t("legend", function () {
                  return [_vm._v("Legend Slot")]
                }),
              ],
              2
            )
          : _vm._e(),
        _vm._t("input"),
        _c(
          "span",
          { staticClass: "opacity_7 font_italic" },
          [_vm._t("hint")],
          2
        ),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "font-size_down" },
      [
        _vm.state == "requiredAlert"
          ? _c("messageHolder", { attrs: { state: "alert" } }, [
              _vm._v("This input is required."),
            ])
          : _vm._e(),
        _vm.state == "alert"
          ? _c(
              "messageHolder",
              { attrs: { state: "alert" } },
              [_vm._t("alertMessage")],
              2
            )
          : _vm._e(),
        _vm.state == "warning"
          ? _c(
              "messageHolder",
              { attrs: { state: "warning" } },
              [_vm._t("warningMessage")],
              2
            )
          : _vm._e(),
        _vm.state == "success"
          ? _c(
              "messageHolder",
              { attrs: { state: "success" } },
              [_vm._t("successMessage")],
              2
            )
          : _vm._e(),
        _vm.state == "info"
          ? _c(
              "messageHolder",
              { attrs: { state: "info" } },
              [_vm._t("infoMessage")],
              2
            )
          : _vm._e(),
        _vm.state == "accent"
          ? _c(
              "messageHolder",
              { attrs: { state: "accent" } },
              [_vm._t("accentMessage")],
              2
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }