var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "ease_out transition_1 f:outline_none text_center br_none inline-block w_auto font_medium font_ui",
      class: [
        _vm.buttonColors,
        "br_" + _vm.corner,
        { p_0: _vm.shape != "button" },
      ],
      staticStyle: { "min-height": "unset" },
      attrs: { disabled: _vm.isDisabled },
      on: {
        click: function ($event) {
          return _vm.onClick()
        },
        focus: function ($event) {
          return _vm.onFocus()
        },
        blur: function ($event) {
          return _vm.onBlur()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "flex block justify_center flex_column",
          class: _vm.buttonSize,
        },
        [
          _c(
            "div",
            {
              staticClass: "flex_auto self_center justify_center flex",
              style: _vm.aspect_ratio,
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }