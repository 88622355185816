var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass:
        "value-space br_solid br_2 p_3 p-y_0 texture_light flex_column flex justify_center",
      class: _vm.areaStyle,
      attrs: { for: _vm.inputNameTarget },
    },
    [_c("i", { staticClass: "fas", class: _vm.icon })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }