import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue' 
import ForgotUserName from '../views/ForgotUserName.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Notifications from '../views/Notifications.vue'
import PreRegister from '../views/PreRegister.vue'
import Registration from '../views/Registration.vue'
window.Vue = Vue
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgotusername',
    name: 'ForgotUserName',
    component: ForgotUserName,
    props: true
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    props: true
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
    props :true
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    props: true
  },
  {
    path: '/preregistration',
    name: 'PreRegistration',
    component: PreRegister
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})
export default router
